import React, { useEffect, useState } from 'react';
import Questionnaire from './Questionnaire';

/*Ce composant React gère l'affichage d'un questionnaire structuré autour
 de plusieurs contextes liés aux diagnostic .*/

const Diagnostic = () => {
  const [contextesDiagnostic, setContextesDiagnostic] = useState([]);

  useEffect(() => {
    const fetchContexteGeneraux = async () => {
      try {
        const contextes = [
          {
            titre: 'Etat des lieux de la gestion des données (ex: typologie, nature, cohérence, disponibilité, sécurité)',
            sousContextes: [
              { titre: 'Gestion des données', questions: [
                { questID: 51, question: 'Y a-t-il une accessibilité aux données ?' },
                { questID: 52, question: 'L\'information est-elle sécurisée ?' },
                { questID: 53, question: 'Est-elle confidentielle ?' },
                { questID: 54, question: 'Respecte-t-elle le RGPD ?' }
              ] }
            ]
          },
          {
            titre: 'Analyse SWOT (Forces, Faiblesses, Opportunités, Risques)',
            sousContextes: [
              { titre: 'SWOT', questions: [
                { questID: 55, question: 'Y a-t-il une connaissance des forces ?' },
                { questID: 56, question: 'Y a-t-il une identification des points faibles ?' },
                { questID: 57, question: 'Y a-t-il une visibilité des opportunités ?' },
                { questID: 58, question: 'Y a-t-il une identification des menaces ?' },
                { questID: 59, question: 'Y a-t-il une gestion des risques ?' }
              ] }
              
            ]
          },
          {
            titre: 'Identification des pain points',
            sousContextes: [
              { titre: 'Irritants', questions: [
                { questID: 65, question: 'Y a-t-il des irritants identifiés ?' },
                { questID: 66, question: 'Sont-ils formalisés ?' },
                { questID: 67, question: 'Sont-ils en cours de traitement ?' }
              ] }
            ]
          },
          {
            titre: 'Pistes d\'amélioration et plan d\'action priorisé',
            sousContextes: [
              { titre: 'Piste amélioration', questions: [
                { questID: 68, question: 'Y a-t-il un processus d\'amélioration ?' },
                { questID: 69, question: 'Y a-t-il une gouvernance ?' },
                { questID: 70, question: 'Y a-t-il une documentation ?' },
                { questID: 71, question: 'Y a-t-il des systèmes ?' },
                { questID: 72, question: 'La base est-elle exploitée ?' }
              ] }
            ]
          },
          {
            titre: 'Etat des lieux des diag',
            sousContextes: [
              { titre: 'Plan d\'action en cours', questions: [
                { questID: 73, question: 'Y a-t-il un plan d\'action en cours pour un processus ?' },
                { questID: 74, question: 'Y a-t-il un plan d\'action en cours pour la gouvernance ?' },
                { questID: 75, question: 'Y a-t-il un plan d\'action en cours pour la documentation ?' },
                { questID: 76, question: 'Y a-t-il des systèmes ?' },
                { questID: 77, question: 'La base est-elle exploitée ?' }
              ] }
            ]
          },
          {
            titre: 'Diagnostic IA',
            sousContextes: [
              { titre: 'Objectifs', questions: [
                { questID: 78, question: 'Y a-t-il des objectifs identifiés ?' },
                { questID: 79, question: 'Les objectifs sont-ils clairs ?' }
              ] },
              { titre: 'Usage souhaité', questions: [
                { questID: 80, question: 'Y a-t-il des solutions internes ?' },
                { questID: 81, question: 'Y a-t-il des solutions externes ?' }
              ] },
              { titre: 'Capacité', questions: [
                { questID: 82, question: 'Y a-t-il une capacité de gestion des données ?' },
                { questID: 83, question: 'Y a-t-il un système d\'information ?' },
                { questID: 84, question: 'Y a-t-il des métiers impliqués ?' }
              ] }
            ]
          }
        ];

        setContextesDiagnostic(contextes);
      } catch (error) {
        console.error('Erreur lors du chargement des contextes:', error);
      }
    };

    fetchContexteGeneraux();
  }, []);

  return <Questionnaire contextesData={contextesDiagnostic} collectionName="diagnostic" />;
};

export default Diagnostic;
