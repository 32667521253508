// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    margin-top: 20px;
  }
  
  .card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .card-body {
    padding: 20px;
  }
  
  h3 {
    color: #0033A0;
    font-weight: bold;
  }
  
  p.mb-0 {
    color: #6c757d;
  }
  
  button.btn-link {
    color: #0033A0;
  }
  
  button.btn-link:hover {
    text-decoration: none;
  }
  
  button.btn-primary {
    background-color: #0033A0;
    border-color: #0033A0;
  }
  
  button.btn-primary:hover {
    background-color: #002080;
    border-color: #002080;
  }
  
  button.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  button.btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
  }
  `, "",{"version":3,"sources":["webpack://./src/userDetails.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,mBAAmB;IACnB,uCAAuC;EACzC;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB","sourcesContent":[".container {\n    margin-top: 20px;\n  }\n  \n  .card {\n    border: none;\n    border-radius: 10px;\n    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);\n  }\n  \n  .card-body {\n    padding: 20px;\n  }\n  \n  h3 {\n    color: #0033A0;\n    font-weight: bold;\n  }\n  \n  p.mb-0 {\n    color: #6c757d;\n  }\n  \n  button.btn-link {\n    color: #0033A0;\n  }\n  \n  button.btn-link:hover {\n    text-decoration: none;\n  }\n  \n  button.btn-primary {\n    background-color: #0033A0;\n    border-color: #0033A0;\n  }\n  \n  button.btn-primary:hover {\n    background-color: #002080;\n    border-color: #002080;\n  }\n  \n  button.btn-secondary {\n    background-color: #6c757d;\n    border-color: #6c757d;\n  }\n  \n  button.btn-secondary:hover {\n    background-color: #5a6268;\n    border-color: #545b62;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
