import React, { useEffect, useState } from 'react';
import Questionnaire from './Questionnaire';

/*Ce composant React gère l'affichage d'un questionnaire structuré autour
 de plusieurs contextes liés aux différents cas d'usage.*/
const UseCase = () => {
  const [contextesUseCase, setContextesUseCase] = useState([]);

  useEffect(() => {
    const fetchContexteGeneraux = async () => {
      try {
        const contextes = [
          {
            titre: "Identification des cas d'usage",
            sousContextes: [
              { titre: 'Cas d\'usage', questions: [
                { questID: 85, question: 'Y a-t-il une liste des cas d\'usage ?' },
                { questID: 86, question: 'Y a-t-il une priorisation sur la résolution des cas d\'usage ?' },
                { questID: 87, question: 'Y a-t-il une résolution des cas d\'usage ?' },
                { questID: 88, question: 'Y a-t-il une évaluation de la valeur liée aux cas d\'usage ?' }
              ]}
            ]
          },
          {
            titre: 'Classification des données',
            sousContextes: [
              { titre: 'Classification', questions: [
                { questID: 89, question: 'Y a-t-il une catégorisation ?' },
                { questID: 90, question: 'Y a-t-il un système d\'analyse ?' },
                { questID: 91, question: 'Y a-t-il une identification des données ?' },
                { questID: 92, question: 'Y a-t-il une protection des données ?' }
              ]}
            ]
          },
          {
            titre: 'Identification des sources de données',
            sousContextes: [
              { titre: 'Identification', questions: [
                { questID: 93, question: 'Y a-t-il une analyse des sources de données ?' },
                { questID: 94, question: 'Y a-t-il une liste des sources de données ?' }
              ]}
            ]
          },
          {
            titre: 'Macro-cartographie des flux',
            sousContextes: [
              { titre: 'Cartographie', questions: [
                { questID: 95, question: 'Y a-t-il une analyse des données ?' },
                { questID: 96, question: 'Y a-t-il un schéma des données ?' },
                { questID: 97, question: 'Y a-t-il un pipeline data stacks ?' }
              ]}
            ]
          },
          {
            titre: "Pistes en vue d'une résolution des cas d'usage et des problématiques pour les métiers",
            sousContextes: [
              { titre: 'Problématique', questions: [
                { questID: 98, question: 'Y a-t-il une connaissance du métier ?' },
                { questID: 99, question: 'Y a-t-il une connaissance du cas d\'usage ?' }
              ]},
              { titre: 'Résolution des cas d\'usage', questions: [
                { questID: 100, question: 'Y a-t-il une identification des problèmes ?' }
              ]},
              { titre: 'Sorties', questions: [
                { questID: 101, question: 'Les sorties sont-elles identifiées ?' },
                { questID: 102, question: 'Y a-t-il un self service ?' },
                { questID: 103, question: 'Y a-t-il la data comme un produit ?' }
              ]}
            ]
          },
          {
            titre: "Identification des cas d'usage IA",
            sousContextes: [
              { titre: 'Cas d\'usage', questions: [
                { questID: 104, question: 'Y a-t-il des processus formalisés ?' },
                { questID: 105, question: 'Y a-t-il une évaluation de la valeur liée aux cas d\'usage ?' }
              ]},
              { titre: 'Solutions', questions: [
                { questID: 106, question: 'Y a-t-il une utilisation interne ?' },
                { questID: 107, question: 'Y a-t-il une utilisation publique ?' },
                { questID: 108, question: 'Y a-t-il une résolution des cas d\'usage ?' }
              ]},
              { titre: 'Sorties', questions: [
                { questID: 109, question: 'Y a-t-il un service client (Chat bot) ?' },
                { questID: 110, question: 'Y a-t-il du marketing ?' },
                { questID: 111, question: 'Y a-t-il de la finance ?' },
                { questID: 112, question: 'Y a-t-il de la supply chain ?' },
                { questID: 113, question: 'Y a-t-il de la performance ?' }
              ]}
            ]
          }
        ];

        setContextesUseCase(contextes);
      } catch (error) {
        console.error('Erreur lors du chargement des contextes:', error);
      }
    };

    fetchContexteGeneraux();
  }, []);

  return <Questionnaire contextesData={contextesUseCase} collectionName="useCase" />;
};

export default UseCase;
