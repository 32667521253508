import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import '../questionnaireForm.css';


/*
 Ce composant React affiche un formulaire de questionnaire basé sur des contextes et sous-contextes dynamiques passés en tant que props.
  
  Fonctionnalités principales :
  - Utilise `useParams` pour récupérer l'ID utilisateur depuis l'URL et `useEffect` pour initialiser l'état des questions et réponses à partir des données de contexte.
  - Le formulaire permet de répondre à des questions de type Oui/Non, ainsi que d'ajouter des commentaires pour chaque question.
  - Les réponses sont enregistrées dans l'état local, puis soumises à une API via Axios lorsque l'utilisateur termine le questionnaire.
  - Le composant gère la navigation entre différents contextes (pages de questions) via les boutons Précédent et Suivant.
  - Lors de la soumission, un score basé sur les réponses Oui est calculé et affiché à l'utilisateur avec un message de confirmation.
  - Après la soumission, l'utilisateur peut continuer vers d'autres questionnaires en étant redirigé vers la liste des questionnaires.
*/
const Questionnaire = ({ contextesData = [], collectionName }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [currentContexteIndex, setCurrentContexteIndex] = useState(0);
  const [questionsReponses, setQuestionsReponses] = useState([]);
  const [message, setMessage] = useState('');
  const [quizResult, setQuizResult] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (contextesData.length > 0) {
      const initialQuestionsReponses = contextesData.flatMap(contexte =>
        contexte.sousContextes.flatMap(sousContexte =>
          sousContexte.questions.map(question => ({
            questID: question.questID,
            reponse: '',
            commentaire: ''
          }))
        )
      );
      setQuestionsReponses(initialQuestionsReponses);
    }
  }, [contextesData]);

  const handleReponseChange = (questID, reponse) => {
    setQuestionsReponses(prev => prev.map(qr =>
      qr.questID === questID ? { ...qr, reponse } : qr
    ));
  };

  const handleCommentChange = (questID, commentaire) => {
    setQuestionsReponses(prev => prev.map(qr =>
      qr.questID === questID ? { ...qr, commentaire } : qr
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      userId: parseInt(userId, 10),
      contexte_general: contextesData.map(contexte => ({
        titre: contexte.titre,
        sous_contextes: contexte.sousContextes.map(sousContexte => ({
          titre: sousContexte.titre,
          questions_reponses: sousContexte.questions.map(question => ({
            questID: question.questID,
            question: question.question, 
            reponse: questionsReponses.find(qr => qr.questID === question.questID)?.reponse || '',
            commentaire: questionsReponses.find(qr => qr.questID === question.questID)?.commentaire || ''
          }))
        }))
      }))
    };
    console.log('Données envoyées :', data);
    try {
      const response = await Axios.post(`http://141.94.245.62:3001/questionnaire/${collectionName}/${userId}`, data);
      console.log('Document créé:', response.data);
      
      const totalQuestions = contextesData.reduce((acc, contexte) => 
        acc + contexte.sousContextes.reduce((accSC, sousContexte) => 
          accSC + sousContexte.questions.length, 0), 0);
      
      const totalYesAnswers = questionsReponses.filter(qr => qr.reponse === '1').length;
      
      setQuizResult(`${totalYesAnswers} / ${totalQuestions}`);
      setMessage('Vos réponses sont transmises avec succès !');
      setSubmitted(true);

      // Enregistrer l'état du questionnaire comme complété
      //localStorage.setItem(`completed-${collectionName}-${userId}`, true);
      localStorage.setItem(`score-${collectionName}-${userId}`, `${totalYesAnswers} / ${totalQuestions}`);

    } catch (error) {
      console.error('Erreur lors de la création du document:', error);
      setMessage('Erreur lors de la création du document. Veuillez réessayer.');
    }
  };

  const handleNext = () => {
    setCurrentContexteIndex(prevIndex => (prevIndex + 1) % contextesData.length);
  };

  const handlePrev = () => {
    setCurrentContexteIndex(prevIndex => (prevIndex - 1 + contextesData.length) % contextesData.length);
  };

  const handleContinue = () => {
    navigate(`/questionnaires-list/${userId}`);
  };

  const currentContexte = contextesData.length > 0 ? contextesData[currentContexteIndex] : null;

  return (
    <Container className="my-4">
      <Card className="shadow-sm">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            {currentContexte ? (
              <>
                <h3 className="mb-4">{currentContexte.titre}</h3>
                {currentContexte.sousContextes.map((sousContexte, sousContexteIndex) => (
                  <div key={sousContexteIndex}>
                    <h4>{sousContexte.titre}</h4>
                    {sousContexte.questions.map((question) => (
                      <div key={question.questID}>
                        <Form.Group as={Row} controlId={`question-${question.questID}`}>
                          <Form.Label column sm="4">{question.question}</Form.Label>
                          <Col sm="8">
                            <Form.Check
                              inline
                              type="radio"
                              label="Oui"
                              checked={questionsReponses.find(qr => qr.questID === question.questID)?.reponse === '1'}
                              onChange={() => handleReponseChange(question.questID, '1')}
                            />
                            <Form.Check
                              inline
                              type="radio"
                              label="Non"
                              checked={questionsReponses.find(qr => qr.questID === question.questID)?.reponse === '0'}
                              onChange={() => handleReponseChange(question.questID, '0')}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-3">
                          <Form.Label column sm="4">Commentaire :</Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={questionsReponses.find(qr => qr.questID === question.questID)?.commentaire || ''}
                              onChange={(e) => handleCommentChange(question.questID, e.target.value)}
                              placeholder="Commentaire"
                            />
                          </Col>
                        </Form.Group>
                      </div>
                    ))}
                  </div>
                ))}
                <div className="d-flex justify-content-between mt-4">
                  {currentContexteIndex > 0 && (
                    <Button variant="outline-secondary" onClick={handlePrev}>
                      <FontAwesomeIcon icon={faArrowLeft} /> Précédent
                    </Button>
                  )}
                  {currentContexteIndex < contextesData.length - 1 && (
                    <Button variant="primary" onClick={handleNext}>
                      Suivant <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  )}
                  {currentContexteIndex === contextesData.length - 1 && (
                    <Button variant="success" type="submit">
                      Soumettre <FontAwesomeIcon icon={faCheckCircle} />
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <p className="text-center">Chargement des données...</p>
            )}
          </Form>
          {message && <p className="mt-4 text-center">{message}</p>}
          {quizResult !== null && (
            <div className="mt-4 text-center">
              <p>Votre score est : {quizResult}</p>
              {submitted && (
                <Button variant="info" onClick={handleContinue}>
                  Continuer avec d'autres questionnaires
                </Button>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Questionnaire;
