import React, { useEffect, useState } from 'react';
import Questionnaire from './Questionnaire';

/*Ce composant React gère l'affichage d'un questionnaire structuré autour
 de plusieurs contextes liés aux roadmaps .*/

const Roadmap = () => {
  const [contextesRoadmap, setContextesRoadmap] = useState([]);

  useEffect(() => {
    const fetchContexteGeneraux = async () => {
      try {
        const contextes = [
          {
            titre: 'Elaboration de la feuille de route pluriannuelle de la stratégie data',
            sousContextes: [
              { titre: 'Stratégie Data', questions: [
                { questID: 171, question: 'Une stratégie Data pluriannuelle est-elle élaborée ?' },
                { questID: 172, question: 'Disposez-vous d\'une vision pluriannuelle claire pour la Data ?' },
                { questID: 173, question: 'Le cycle de vie complet des données est-il pris en compte dans la stratégie ?' }
              ] }
            ]
          },
          {
            titre: 'Co-construction et partage avec les acteurs',
            sousContextes: [
              { titre: 'Build', questions: [
                { questID: 174, question: 'La solution Data est-elle co-construite avec les acteurs internes ?' },
                { questID: 175, question: 'Y a-t-il un partage interne restreint de la stratégie Data ?' },
                { questID: 176, question: 'Y a-t-il un partage interne élargi de la stratégie Data ?' },
                { questID: 177, question: 'Y a-t-il un partage externe de la stratégie Data avec des partenaires clés ?' },
                { questID: 178, question: 'Y a-t-il un partage avec l\'écosystème plus large ?' }
              ]}
            ]
          },
          {
            titre: 'Déclinaison en programme, projets et actions (dont quick win)',
            sousContextes: [
              { titre: 'Stratégie projets', questions: [
                { questID: 179, question: 'La stratégie Data est-elle déclinée en multi-programmes ?' },
                { questID: 180, question: 'Y a-t-il des projets spécifiques identifiés ?' },
                { questID: 181, question: 'Un project portfolio est-il en place pour gérer les projets Data ?' },
                { questID: 182, question: 'Souhaitez-vous identifier des quick wins pour démontrer rapidement la valeur des initiatives Data ?' }
              ]},
              { titre: 'Progrès et  succès de la roadmap stratégique', questions: [
                { questID: 179, question: 'mesurez-vous les progrès et le succès de votre roadmap stratégique ?' },
                { questID: 180, question: 'mesurez-vous les progrès et le succès de votre roadmap stratégique avec KPIs spécifiques ?' },
                { questID: 181, question: 'inclut-elle des initiatives pour améliorer la culture data au sein de l\'organisation ?' },
                { questID: 182, question: 'inclut-elle des partenariats avec des tiers pour accélérer le développement des capacités data/IA ?' }
              ]}
            ]
          }
        ];

        setContextesRoadmap(contextes);
      } catch (error) {
        console.error('Erreur lors du chargement des contextes:', error);
      }
    };

    fetchContexteGeneraux();
  }, []);

  return <Questionnaire contextesData={contextesRoadmap} collectionName="roadmap" />;
};

export default Roadmap;
