import React, { useState } from 'react';
import Axios from 'axios';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';

const UserForm = () => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [telephone, setTelephone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    Axios.post('http://141.94.245.62:3001/addPostgresUser', {
      nom,
      prenom,
      email,
      password,
      telephone,
    })
      .then((res) => {
        setMessage('Utilisateur créé avec succès !');
        setNom('');
        setPrenom('');
        setEmail('');
        setPassword('');
        setTelephone('');
      })
      .catch((error) => {
        setMessage("Erreur lors de la création de l'utilisateur. Veuillez réessayer.");
        console.error("Erreur lors de la création de l'utilisateur :", error);
      });
  };

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <Row className="w-100">
        <Col md={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Inscription</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicNom">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Entrez votre nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPrenom" className="mt-3">
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Entrez votre prénom"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail" className="mt-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Entrez votre email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicTelephone" className="mt-3">
                  <Form.Label>Téléphone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Entrez votre numéro de téléphone"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className="mt-3">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 mt-3">
                  S'inscrire
                </Button>
              </Form>
              {message && <p className="mt-3 text-center">{message}</p>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserForm;
