import React, { useEffect, useState } from 'react';
import Questionnaire from './Questionnaire';

/*Ce composant React gère l'affichage d'un questionnaire structuré autour
 de plusieurs contextes liés à l'architecture de données.*/

const Architecture = () => {
  const [contextesArchitecture, setContextesArchitecture] = useState([]);

  useEffect(() => {
    const fetchContexteGeneraux = async () => {
      try {
        const contextes = [
          {
            titre: 'Définition des modèles de données',
            sousContextes: [
              { titre: 'Modèles de données', questions: [
                { questID: 125, question: 'Y a-t-il un travail de conceptualisation ?' },
                { questID: 126, question: 'Y a-t-il une identification du modèle logique ?' },
                { questID: 127, question: 'Y a-t-il une définition du modèle physique ?' },
                { questID: 128, question: 'Y a-t-il du No SQL ?' }
              ] },
              { titre: 'Types', questions: [
                { questID: 129, question: 'Y a-t-il du relationnel ?' },
                { questID: 130, question: 'Y a-t-il du hiérarchique ?' },
                { questID: 131, question: 'Y a-t-il du réseau ?' },
                { questID: 132, question: 'Y a-t-il de l\'orienté objet ?' },
                { questID: 133, question: 'Y a-t-il de l\'entité relation ?' }
              ] },
              { titre: 'Types NoSQL', questions: [
                { questID: 134, question: 'Y a-t-il du documentaire ?' },
                { questID: 135, question: 'Y a-t-il du graphe ?' },
                { questID: 136, question: 'Y a-t-il du colonne ?' },
                { questID: 137, question: 'Y a-t-il du key/value ?' }
              ] }
            ]
          },
          {
            titre: 'Architecture des données',
            sousContextes: [
              { titre: 'Travail d\'optimisation de l\'architecture par rapport au modèle', questions: [
                { questID: 138, question: 'Y a-t-il une solution formalisée ?' },
                { questID: 139, question: 'Y a-t-il une solution structurée (traditionnelle à minima) ?' },
                { questID: 140, question: 'Y a-t-il une solution moderne Médaillon ?' },
                { questID: 141, question: 'Y a-t-il une solution Data Fabrik ?' },
                { questID: 142, question: 'Y a-t-il une solution Data Mesh ?' }
              ]}
            ]
          },
          {
            titre: 'Organisation de la Data Stack',
            sousContextes: [
              { titre: 'Data Stack', questions: [
                { questID: 143, question: 'Les sources sont-elles identifiées ?' },
                { questID: 144, question: 'Y a-t-il du stockage des données ?' },
                { questID: 145, question: 'Y a-t-il du traitement ?' },
                { questID: 146, question: 'Y a-t-il de la BI ?' }
              ]}
            ]
          },
          {
            titre: 'Stockage des données (ex: data warehouse, data lake)',
            sousContextes: [
              { titre: 'Stockage', questions: [
                { questID: 147, question: 'Y a-t-il des données sensibles ?' },
                { questID: 148, question: 'Y a-t-il des données structurées ?' },
                { questID: 149, question: 'Y a-t-il un schéma à l\'écriture ?' },
                { questID: 150, question: 'Y a-t-il une utilisation business ?' },
                { questID: 151, question: 'Y a-t-il un backup ?' }
              ]}
            ]
          },
          {
            titre: 'Schéma de l’architecture actuelle et de son évolution possible',
            sousContextes: [
              { titre: 'Schéma', questions: [
                { questID: 152, question: 'Y a-t-il un diagramme ?' },
                { questID: 153, question: 'Y a-t-il une matrice de données ?' },
                { questID: 154, question: 'Y a-t-il de la documentation ?' },
                { questID: 155, question: 'Y a-t-il du data lineage ?' }
              ]}
            ]
          },
          {
            titre: 'Historique',
            sousContextes: [
              { titre: 'Industrie', questions: [
                { questID: 156, question: 'Y a-t-il de l\'éducation/formation ?' },
                { questID: 157, question: 'Y a-t-il de la finance et assurance ?' },
                { questID: 158, question: 'Y a-t-il de l\'industriel ?' },
                { questID: 159, question: 'Y a-t-il de la santé ?' },
                { questID: 160, question: 'Y a-t-il de la logistique ?' }
              ]},
              { titre: 'Positionnement', questions: [
                { questID: 161, question: 'Y a-t-il un éditeur logiciel ?' },
                { questID: 162, question: 'Y a-t-il un exploitant ?' },
                { questID: 163, question: 'Y a-t-il un mix profil ?' }
              ]},
              { titre: 'Solution', questions: [
                { questID: 164, question: 'Y a-t-il un chatbot ?' },
                { questID: 165, question: 'Y a-t-il de l\'IA générative ?' },
                { questID: 166, question: 'Y a-t-il du prévisionnel ?' },
                { questID: 167, question: 'Y a-t-il du traitement (texte ou image) ?' },
                { questID: 168, question: 'Y a-t-il de la robotique ?' }
              ]},
              { titre: 'Souhait', questions: [
                { questID: 169, question: 'Y a-t-il le besoin de construire une solution propre ?' },
                { questID: 170, question: 'Y a-t-il le besoin d\'acheter une solution ?' }
              ]}
            ]
          }
        ];

        setContextesArchitecture(contextes);
      } catch (error) {
        console.error('Erreur lors du chargement des contextes:', error);
      }
    };

    fetchContexteGeneraux();
  }, []);

  return <Questionnaire contextesData={contextesArchitecture} collectionName="architecture" />;
};

export default Architecture;
