import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUser, faSignOutAlt, faQuestionCircle, faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';


import '../bienvenue.css'; 

//Ce composant React gère l'affichage de la page de bienvenue pour l'utilisateur après sa connexion.
const Bienvenue = ({ onLogout }) => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [prenom, setPrenom] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Vérifiez si l'utilisateur est connecté (par exemple, via une API ou un contexte global)
    Axios.get(`http://141.94.245.62:3001/user/${userId}`)
      .then(response => {
        setPrenom(response.data.prenom);
        setIsLoggedIn(true); // Supposons que si nous récupérons les données, l'utilisateur est connecté
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des informations personnelles:', error);
        setIsLoggedIn(false); // Si une erreur se produit, l'utilisateur n'est pas connecté
      });
  }, [userId]);
  const handleQuestionnaire = () => {
    navigate(`/questionnaires-list/${userId}`);
  };

  const handleShowDetails = async () => {
    try {
      const response = await Axios.get(`http://141.94.245.62:3001/user/${userId}`);
      navigate('/details', { state: { userDetails: response.data } });
    } catch (error) {
      console.error('Erreur lors de la récupération des informations personnelles:', error);
    }
  };

  const handleLogoutClick = () => {
    onLogout();
    navigate('/connexion');
  };

  const handleDashboardClick = () => {
    navigate(`/dashboard/${userId}`);
  };


  
  const handleLoginClick = () => {
    navigate('/connexion');
  };

  const handleSignupClick = () => {
    navigate('/inscription');
  };

  return (
    <Container fluid className="d-flex">
      <div className="sidebar">
        {isLoggedIn ? (
          <>
            <Button variant="link" className="text-white" onClick={handleDashboardClick}>
              <FontAwesomeIcon icon={faTachometerAlt} className="icon" />
              <span className="text">Dashboard</span>
            </Button>
            <Button variant="link" className="text-white" onClick={handleShowDetails}>
              <FontAwesomeIcon icon={faUser} className="icon" />
              <span className="text">Informations personnelles</span>
            </Button>
            <Button variant="link" className="text-white" onClick={handleQuestionnaire}>
              <FontAwesomeIcon icon={faQuestionCircle} className="icon" />
              <span className="text">Faire mon questionnaire</span>
            </Button>
            <Button variant="link" className="text-white mt-auto" onClick={handleLogoutClick}>
              <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
              <span className="text">Se déconnecter</span>
            </Button>
          </>
        ) : (
          <>
            <Button variant="link" className="text-white" onClick={handleLoginClick}>
              <FontAwesomeIcon icon={faSignInAlt} className="icon" />
              <span className="text">Se connecter</span>
            </Button>
            <Button variant="link" className="text-white" onClick={handleSignupClick}>
              <FontAwesomeIcon icon={faUserPlus} className="icon" />
              <span className="text">S'inscrire</span>
            </Button>
          </>
        )}
      </div>
      <Container className="container-main">
        <Row className="w-100">
          <Col md={{ span: 6, offset: 3 }}>
            <Card className="card-custom">
              <Card.Body>
                <h2 className="text-center mb-4">Bienvenue {prenom} !</h2>
                <p className="text-center">ID utilisateur : {userId}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Bienvenue;
