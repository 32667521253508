import React, { useEffect, useState } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import '../QuestionnairesList.css';


/*
 Ce composant React affiche une liste de types de questionnaires que l'utilisateur peut remplir.

  Fonctionnalités principales :
  - Utilise `useParams` pour récupérer l'ID utilisateur depuis l'URL et `useEffect` pour calculer et afficher le score général basé sur les résultats des différents questionnaires.
  - La liste des questionnaires est définie sous forme de tableau d'objets, contenant les informations de chaque questionnaire (type, titre, description).
  - Pour chaque questionnaire, un bouton Commencer redirige l'utilisateur vers le formulaire correspondant en utilisant `react-router-dom`.
  - Les scores des questionnaires sont récupérés depuis `localStorage` et utilisés pour calculer un score général que l'on affiche à l'utilisateur (si disponible).
  - Le score général est calculé en additionnant le nombre total de réponses Oui (1) et le nombre total de questions de tous les questionnaires complétés.
*/
const QuestionnairesList = () => {
  const { userId } = useParams();
  const [generalScore, setGeneralScore] = useState(null);

  console.log('User ID:', userId); 
  // Liste des types de questionnaires avec leurs détails
  const questionnaires = [
    { type: 'vision', title: 'Vision', description: 'Remplir le questionnaire de Vision pour évaluer votre contexte.' },
    { type: 'diagnostic', title: 'Diagnostic', description: 'Remplir le questionnaire de Diagnostic pour évaluer votre situation.' },
    { type: 'gouvernance', title: 'Gouvernance', description: 'Remplir le questionnaire de Gouvernance pour évaluer la gestion de vos données.' },
    { type: 'useCase', title: 'Cas d\'usage', description: 'Remplir le questionnaire de Cas d\'usage pour identifier les solutions à vos problèmes.' },
    { type: 'architecture', title: 'Architecture', description: 'Remplir le questionnaire d\'Architecture pour évaluer la structure de vos données.' },
    { type: 'roadmap', title: 'Roadmap', description: 'Remplir le questionnaire de Roadmap pour planifier votre stratégie Data.' }
  ];
  
  useEffect(() => {
    // Calculer le score général
    const scores = questionnaires
      .map(q => localStorage.getItem(`score-${q.type}-${userId}`))
      .filter(Boolean);

    if (scores.length > 0) {
      const totalYesAnswers = scores.reduce((acc, score) => acc + parseInt(score.split(' / ')[0], 10), 0);
      const totalQuestions = scores.reduce((acc, score) => acc + parseInt(score.split(' / ')[1], 10), 0);
      const calculatedGeneralScore = `${totalYesAnswers} / ${totalQuestions}`;
      setGeneralScore(calculatedGeneralScore);

      // Stocker le score général dans localStorage
      // localStorage.setItem(`generalScore-${userId}`, calculatedGeneralScore);
    }
  }, [userId, questionnaires]);

  return (
    <Container className="my-4">
      <div className="d-flex flex-wrap justify-content-center">
        {questionnaires.map((q) => {
          // const isCompleted = localStorage.getItem(`completed-${q.type}-${userId}`);
          return (
            <Card className="questionnaire-card" key={q.type}>
              <Card.Body>
                <Card.Title>{q.title}</Card.Title>
                <Card.Text>
                  {q.description}
                </Card.Text>
                {/* {isCompleted ? (
                  <Button variant="secondary" disabled>Déjà complété</Button>
                ) : ( */}
                  <Link to={`/questionnaire/${q.type}/${userId}`}>
                    <Button variant="primary">Commencer</Button>
                  </Link>
                {/* )} */}
              </Card.Body>
            </Card>
          );
        })}
      </div>
      {generalScore && (
        <div className="mt-4 text-center">
          <h4>Score général: {generalScore}</h4>
        </div>
      )}
    </Container>
  );
};
export default QuestionnairesList;
