import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const Dashboard = () => {
  const { userId } = useParams(); 

// Récupère l'URL de base à partir de la variable d'environnement, avec un fallback en local.
const baseUrl = process.env.REACT_APP_DASHBOARD_URL
 || 'http://127.0.0.1:8051';

  return (
    <Container fluid className="d-flex flex-column" style={{ height: '100vh', padding: 0 }}>
      <Row className="flex-grow-1" style={{ margin: 0 }}>
        <Col style={{ padding: 0, height: '100%' }}>
          <iframe
              //src={`http://127.0.0.1:8051/?userId=${userId}`}
              src={`${baseUrl}/?userId=${userId}`}
              style={{ width: '100%', height: '100vh', border: 'none' }}
              title="Dashboard"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
