import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faLock, faSave } from '@fortawesome/free-solid-svg-icons';
import '../userDetails.css'; 

const UserDetails = () => {
  const location = useLocation();
  const { userDetails } = location.state;

  const [user, setUser] = useState({
    id: userDetails.id,
    nom: userDetails.nom,
    prenom: userDetails.prenom,
    email: userDetails.email,
    telephone: userDetails.telephone,
    password: '',
  });

  const [editField, setEditField] = useState(null);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value
    });
  };

  const handleEdit = (field) => {
    setEditField(field);
  };

  const handleSave = async () => {
    try {
      const response = await axios.put('http://141.94.245.62:3001/updateUser', user);
      setEditField(null);
      setMessage('Les informations ont été mises à jour avec succès.');
    } catch (error) {
      console.error('Erreur lors de la mise à jour des informations :', error);
      setMessage('Erreur lors de la mise à jour des informations.');
    }
  };

  const handlePasswordChange = async () => {
    try {
      const response = await axios.put('http://141.94.245.62:3001/updatePassword', {
        id: user.id,
        password: user.password,
      });
      setUser({ ...user, password: '' });
      setEditField(null);
      setMessage('Le mot de passe a été mis à jour avec succès.');
    } catch (error) {
      console.error('Erreur lors de la mise à jour du mot de passe :', error);
      setMessage('Erreur lors de la mise à jour du mot de passe.');
    }
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card className="shadow-sm">
            <Card.Body>
              <h3 className="mb-4">Informations personnelles</h3>
              {message && <p className="text-center text-success">{message}</p>}
              <Form>
                <Form.Group controlId="formBasicNom">
                  <Form.Label>
                    <FontAwesomeIcon icon={faUser} /> Nom:
                  </Form.Label>
                  {editField === 'nom' ? (
                    <Form.Control
                      type="text"
                      name="nom"
                      value={user.nom}
                      onChange={handleChange}
                    />
                  ) : (
                    <div className="d-flex justify-content-between">
                      <p className="mb-0">{user.nom}</p>
                      <Button variant="link" onClick={() => handleEdit('nom')}>✏️</Button>
                    </div>
                  )}
                </Form.Group>
                <Form.Group controlId="formBasicPrenom" className="mt-3">
                  <Form.Label>
                    <FontAwesomeIcon icon={faUser} /> Prénom:
                  </Form.Label>
                  {editField === 'prenom' ? (
                    <Form.Control
                      type="text"
                      name="prenom"
                      value={user.prenom}
                      onChange={handleChange}
                    />
                  ) : (
                    <div className="d-flex justify-content-between">
                      <p className="mb-0">{user.prenom}</p>
                      <Button variant="link" onClick={() => handleEdit('prenom')}>✏️</Button>
                    </div>
                  )}
                </Form.Group>
                <Form.Group controlId="formBasicEmail" className="mt-3">
                  <Form.Label>
                    <FontAwesomeIcon icon={faEnvelope} /> Email:
                  </Form.Label>
                  {editField === 'email' ? (
                    <Form.Control
                      type="email"
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                    />
                  ) : (
                    <div className="d-flex justify-content-between">
                      <p className="mb-0">{user.email}</p>
                      <Button variant="link" onClick={() => handleEdit('email')}>✏️</Button>
                    </div>
                  )}
                </Form.Group>
                <Form.Group controlId="formBasicTelephone" className="mt-3">
                  <Form.Label>
                    <FontAwesomeIcon icon={faPhone} /> Téléphone:
                  </Form.Label>
                  {editField === 'telephone' ? (
                    <Form.Control
                      type="text"
                      name="telephone"
                      value={user.telephone}
                      onChange={handleChange}
                    />
                  ) : (
                    <div className="d-flex justify-content-between">
                      <p className="mb-0">{user.telephone}</p>
                      <Button variant="link" onClick={() => handleEdit('telephone')}>✏️</Button>
                    </div>
                  )}
                </Form.Group>
                {editField && (
                  <Button variant="primary" className="mt-3" onClick={handleSave}>
                    <FontAwesomeIcon icon={faSave} /> Enregistrer les modifications
                  </Button>
                )}
                <Form.Group controlId="formBasicPassword" className="mt-3">
                  <Form.Label>
                    <FontAwesomeIcon icon={faLock} /> Mot de passe:
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                  />
                  <Button variant="secondary" className="mt-2" onClick={handlePasswordChange}>
                    <FontAwesomeIcon icon={faSave} /> Changer le mot de passe
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserDetails;
