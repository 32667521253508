import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserForm from './components/UserForm';
import LoginForm from './components/LoginForm';
import Bienvenue from './components/Bienvenue';
import UserDetails from './components/UserDetails';
import Vision from './components/vision';
import Diagnostic from './components/diagnostic';
import Gouvernance from './components/gouvernance';
import Architecture from './components/architecture';
import UseCase from './components/useCase';
import Roadmap from './components/roadmap';
import { Navbar, Nav, Container } from 'react-bootstrap'; 
import Questionnaire from './components/Questionnaire';
import QuestionnairesList from './components/QuestionnairesList';
import Dashboard from './components/dashboard';
import './style_global.css'; 

/*
Routage : 
    - Configuration des routes pour chaque composant 
*/
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <div>
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {isLoggedIn ? (
                  <Nav.Link as={Link} to="/connexion" onClick={handleLogout}>
                    Se déconnecter
                  </Nav.Link>
                ) : (
                  <>
                    <Nav.Link as={Link} to="/inscription">S'inscrire</Nav.Link>
                    <Nav.Link as={Link} to="/connexion">Se connecter</Nav.Link>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Routes>
          <Route path="/inscription" element={<UserForm />} />
          <Route
            path="/connexion"
            element={<LoginForm onLogin={handleLogin} />}
          />
          <Route path="/bienvenue/:userId" element={<Bienvenue onLogout={handleLogout} />} />
          <Route path="/dashboard/:userId" element={<Dashboard />} />
          <Route path="/questionnaire/vision/:userId" element={<Vision />} />
          <Route path="/questionnaire/diagnostic/:userId" element={<Diagnostic />} />
          <Route path="/questionnaire/gouvernance/:userId" element={<Gouvernance />} />
          <Route path="/questionnaire/architecture/:userId" element={<Architecture />} />
          <Route path="/questionnaire/useCase/:userId" element={<UseCase />} />
          <Route path="/questionnaire/roadmap/:userId" element={<Roadmap />} />
          <Route path="/questionnaires-list/:userId" element={<QuestionnairesList />} />
          <Route path="/details" element={<UserDetails />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
