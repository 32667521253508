import React, { useEffect, useState } from 'react';
import Questionnaire from './Questionnaire';

/*Ce composant React gère l'affichage d'un questionnaire structuré autour
 de plusieurs contextes liés à la gouvernance des données.*/
const Gouvernance = () => {
  const [contextesGouvernance, setContextesGouvernance] = useState([]);

  useEffect(() => {
    const fetchContexteGeneraux = async () => {
      try {
        const contextes = [
          {
            titre: 'Identification des acteurs et parties-prenantes sur les niveaux stratégiques, opérationnels et tactiques',
            sousContextes: [
              { titre: 'Identification', questions: [{ questID: 114, question: 'Y a-t-il une organisation identifiée ? (Rôles et responsabilités)' }] }
            ]
          },
          {
            titre: 'Structure de la gouvernance des données',
            sousContextes: [
              { titre: 'Structure de la gouvernance', questions: [
                { questID: 115, question: 'Y a-t-il un processus de structure de la gouvernance ?' },
                { questID: 116, question: 'Y a-t-il des principes et des politiques ?' },
                { questID: 117, question: 'Y a-t-il des outils ?' }
              ]}
            ]
          },
          {
            titre: 'Référentiel de données',
            sousContextes: [
              { titre: 'Référentiel', questions: [
                { questID: 118, question: 'Y a-t-il des outils ?' },
                { questID: 119, question: 'Les données sont-elles centralisées ?' }
              ]}
            ]
          },
          {
            titre: 'Rôle des acteurs et évolutions possibles en vue d’une meilleure utilisation et gestion des données au sein de l’entreprise',
            sousContextes: [
              { titre: 'Rôle des acteurs présents dans l\'organisation', questions: [
                { questID: 120, question: 'Y a-t-il une présence d\'un COMEX ?' },
                { questID: 121, question: 'Y a-t-il un CDO ?' },
                { questID: 122, question: 'Y a-t-il un DPO ?' },
                { questID: 123, question: 'Y a-t-il un DSI ?' },
                { questID: 124, question: 'Y a-t-il des métiers data ?' }
              ]}
            ]
          }
        ];

        setContextesGouvernance(contextes);
      } catch (error) {
        console.error('Erreur lors du chargement des contextes:', error);
      }
    };

    fetchContexteGeneraux();
  }, []);

  return <Questionnaire contextesData={contextesGouvernance} collectionName="gouvernance" />;
};

export default Gouvernance;
