// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Brother+1916&family=Azo+Sans&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Importer les polices depuis Google Fonts */

/* Appliquer Azo Sans comme police par défaut pour tout le contenu */
body, html, .container, .row, .col, .header-font {
    font-family: 'Azo Sans', sans-serif;
}

/* Appliquer Brother 1916 spécifiquement pour les titres */
h1, h2, h3, h4, h5, h6, .header-font {
    font-family: 'Brother 1916', sans-serif;
}

/* Appliquer des polices spécifiques aux éléments Dash */
.dash-graph-title, .dash-graph-subtitle, .dash-table, .dash-label {
    font-family: 'Azo Sans', sans-serif;
}

/* Couleurs de fond et de texte */

html, body {
    background-color: #F4F3F2;
    color: #000000;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto; /* Permet le défilement vertical si nécessaire */
  }
`, "",{"version":3,"sources":["webpack://./src/style_global.css"],"names":[],"mappings":"AAAA,6CAA6C;;AAG7C,oEAAoE;AACpE;IACI,mCAAmC;AACvC;;AAEA,0DAA0D;AAC1D;IACI,uCAAuC;AAC3C;;AAEA,wDAAwD;AACxD;IACI,mCAAmC;AACvC;;AAEA,iCAAiC;;AAEjC;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,SAAS;IACT,UAAU;IACV,gBAAgB,EAAE,gDAAgD;EACpE","sourcesContent":["/* Importer les polices depuis Google Fonts */\n@import url('https://fonts.googleapis.com/css2?family=Brother+1916&family=Azo+Sans&display=swap');\n\n/* Appliquer Azo Sans comme police par défaut pour tout le contenu */\nbody, html, .container, .row, .col, .header-font {\n    font-family: 'Azo Sans', sans-serif;\n}\n\n/* Appliquer Brother 1916 spécifiquement pour les titres */\nh1, h2, h3, h4, h5, h6, .header-font {\n    font-family: 'Brother 1916', sans-serif;\n}\n\n/* Appliquer des polices spécifiques aux éléments Dash */\n.dash-graph-title, .dash-graph-subtitle, .dash-table, .dash-label {\n    font-family: 'Azo Sans', sans-serif;\n}\n\n/* Couleurs de fond et de texte */\n\nhtml, body {\n    background-color: #F4F3F2;\n    color: #000000;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    overflow-y: auto; /* Permet le défilement vertical si nécessaire */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
