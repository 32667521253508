// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionnaire-card {
    width: 300px;
    margin: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .questionnaire-card:hover {
    background: linear-gradient(135deg, #6DBE45, #40B4D6);
    transform: scale(1.05);
  }
  
  .questionnaire-card .card-body {
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/QuestionnairesList.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,2DAA2D;IAC3D,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,qDAAqD;IACrD,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".questionnaire-card {\n    width: 300px;\n    margin: 10px;\n    transition: background-color 0.3s ease, transform 0.3s ease;\n    cursor: pointer;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n  }\n  \n  .questionnaire-card:hover {\n    background: linear-gradient(135deg, #6DBE45, #40B4D6);\n    transform: scale(1.05);\n  }\n  \n  .questionnaire-card .card-body {\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
