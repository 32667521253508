// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    margin-top: 20px;
  }
  
  .card {
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .card-body {
    padding: 20px;
  }
  
  h2 {
    color: #0033A0;
    font-weight: bold;
  }
  
  h3 {
    color: #0033A0;
  }
  
  button {
    font-size: 16px;
  }
  
  button.btn-outline-secondary {
    border-color: #6c757d;
    color: #6c757d;
  }
  
  button.btn-outline-secondary:hover {
    background-color: #6c757d;
    color: white;
  }
  
  button.btn-primary {
    background-color: #0033A0;
    border-color: #0033A0;
  }
  
  button.btn-primary:hover {
    background-color: #002080;
    border-color: #002080;
  }
  
  button.btn-success {
    background-color: #28a745;
    border-color: #28a745;
  }
  
  button.btn-success:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  button.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  button.btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
  }
  
  p.text-center {
    font-size: 18px;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/questionnaireForm.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB","sourcesContent":[".container {\n    margin-top: 20px;\n  }\n  \n  .card {\n    border-radius: 8px;\n    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);\n  }\n  \n  .card-body {\n    padding: 20px;\n  }\n  \n  h2 {\n    color: #0033A0;\n    font-weight: bold;\n  }\n  \n  h3 {\n    color: #0033A0;\n  }\n  \n  button {\n    font-size: 16px;\n  }\n  \n  button.btn-outline-secondary {\n    border-color: #6c757d;\n    color: #6c757d;\n  }\n  \n  button.btn-outline-secondary:hover {\n    background-color: #6c757d;\n    color: white;\n  }\n  \n  button.btn-primary {\n    background-color: #0033A0;\n    border-color: #0033A0;\n  }\n  \n  button.btn-primary:hover {\n    background-color: #002080;\n    border-color: #002080;\n  }\n  \n  button.btn-success {\n    background-color: #28a745;\n    border-color: #28a745;\n  }\n  \n  button.btn-success:hover {\n    background-color: #218838;\n    border-color: #1e7e34;\n  }\n  \n  button.btn-secondary {\n    background-color: #6c757d;\n    border-color: #6c757d;\n  }\n  \n  button.btn-secondary:hover {\n    background-color: #5a6268;\n    border-color: #545b62;\n  }\n  \n  p.text-center {\n    font-size: 18px;\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
