import React, { useEffect, useState } from 'react';
import Questionnaire from './Questionnaire';

/*Ce composant React gère l'affichage d'un questionnaire structuré autour
 de plusieurs contextes liés a la vision del entreprise .*/

const Vision = () => {
  const [contextesVision, setContextesVision] = useState([]);

  useEffect(() => {
    const fetchContexteGeneraux = async () => {
      try {
        const contextes = [
          {
            titre: 'Contexte, enjeux et objectifs généraux de l\'organisation',
            sousContextes: [
              { titre: 'Contexte général de l\'entreprise', questions: [{ questID: 1, question: 'Le contexte de l\'entreprise est-il formalisé ?' }] },
              { titre: 'Enjeux généraux', questions: [
                { questID: 2, question: 'Les enjeux de l\'entreprise sont-ils formalisés ?' },
                { questID: 3, question: 'Les enjeux de l\'entreprise sont-ils suivis ?' },
                { questID: 4, question: 'Un plan de transformation a-t-il été mis en place ?' }
              ] },
              { titre: 'Objectifs généraux', questions: [
                { questID: 5, question: 'Les objectifs de l\'entreprise sont-ils formalisés ?' },
                { questID: 6, question: 'Les objectifs sont-ils mesurés ?' },
                { questID: 7, question: 'Les objectifs sont-ils mesurés avec des outils ?' },
                { questID: 8, question: 'y a-t-il un plan stratégique ?' }
              ] }
            ]
          },
          {
            titre: 'Contexte, enjeux et objectifs data au sein de l\'organisation',
            sousContextes: [
              { titre: 'Contexte data', questions: [{ questID: 9, question: 'Le contexte data de l\'entreprise est-il formalisé ?' }] },
              { titre: 'Enjeux data', questions: [
                { questID: 10, question: 'Les enjeux data de l\'entreprise sont-ils formalisés ?' },
                { questID: 11, question: 'Les enjeux data de l\'entreprise sont-ils suivis ?' }
              ] },
              { titre: 'Objectifs data', questions: [
                { questID: 12, question: 'Les objectifs data de l\'entreprise sont-ils formalisés ?' },
                { questID: 13, question: 'Les objectifs data sont-ils mesurés ?' },
                { questID: 14, question: 'Les objectifs data sont-ils mesurés avec des outils ?' },
                { questID: 15, question: 'y a-t-il une roadmap stratégique ?' }
              ] }
            ]
          },
          {
            titre: 'Contexte, enjeux et objectifs IT au sein de l\'organisation',
            sousContextes: [
              { titre: 'Contexte IT', questions: [{ questID: 16, question: 'Le contexte IT de l\'entreprise est-il formalisé ?' }] },
              { titre: 'Enjeux IT', questions: [
                { questID: 17, question: 'Les enjeux IT de l\'entreprise sont-ils formalisés ?' },
                { questID: 18, question: 'Les enjeux IT de l\'entreprise sont-ils suivis ?' }
              ] },
              { titre: 'Objectifs IT', questions: [
                { questID: 19, question: 'Les objectifs IT data de l\'entreprise sont-ils formalisés ?' },
                { questID: 20, question: 'Les objectifs IT sont-ils mesurés ?' },
                { questID: 21, question: 'Les objectifs IT sont-ils mesurés avec des outils ?' },
                { questID: 22, question: 'y a-t-il un schéma directeur ?' }
              ] }
            ]
          },
          {
            titre: 'Contexte, enjeux et objectifs IA au sein de l\'organisation',
            sousContextes: [
              { titre: 'Contexte IA', questions: [{ questID: 23, question: 'Le contexte IA de l\'entreprise est-il formalisé ?' }] },
              { titre: 'Enjeux IA', questions: [
                { questID: 24, question: 'Les enjeux IA de l\'entreprise sont-ils formalisés ?' },
                { questID: 25, question: 'Les enjeux IA de l\'entreprise sont-ils suivis ?' }
              ] },
              { titre: 'Objectifs IA', questions: [
                { questID: 26, question: 'Les objectifs IA de l\'entreprise sont-ils formalisés ?' },
                { questID: 27, question: 'Les objectifs IA sont-ils traditionnellement mesurés ?' },
                { questID: 28, question: 'Les objectifs IA sont-ils mesurés avec des outils IA ?' },
                { questID: 29, question: 'y a-t-il un schéma directeur ?' }
              ] }
            ]
          },
          {
            titre: 'Identification des projets clefs et de la valeur data dans l\'entreprise',
            sousContextes: [
              { titre: 'Projets clefs', questions: [
                { questID: 30, question: 'Les projets clefs  IA de l\'entreprise sont-ils identifiés ?' },
                { questID: 31, question: 'Y a-t-il un portefeuille projet ?' },
                { questID: 32, question: 'Les projets ont-ils été mis en œuvre ?' },
                { questID: 33, question: 'y a-t-il des projets pilotés ? ' },
                { questID: 34, question: 'y a-t-il REX des projets et amélioration continue ?'  }
              ] },
              { titre: 'Valeur data', questions: [
                { questID: 35, question: 'La valeur data de l\'entreprise est-elle identifiée globalement ?' },
                { questID: 36, question: 'La valeur data de l\'entreprise est-elle identifiée par métier ?' },
                { questID: 37, question: 'La valeur data de l\'entreprise est-elle mesurée par un cas d\'usage ?' },
                { questID: 38, question: 'est-elle utilisée par le business ?' },
                { questID: 39, question: 'y a-t-il un socle du modèle d\'affaire ? ' }
              ] }
            ]
          },
          {
            titre: 'Identification des projets  IA  dans l\'entreprise',
            sousContextes: [
              { titre: 'Projets IA', questions: [
                { questID: 40, question: 'Les projets IA de l\'entreprise sont-ils identifiés ?' },
                { questID: 41, question: 'Y a-t-il une étude sur le projet ?' },
                { questID: 42, question: 'Les projets ont-ils été mis en œuvre ?' },
                { questID: 43, question: 'y a-t-il une analyse des risques ?' }
              ] }
            ]
          },
          {
            titre: 'Préfiguration de la feuille de route stratégique pluriannuelle',
            sousContextes: [
              { titre: 'Feuille de route stratégique', questions: [
                { questID: 44, question: 'La feuille de route stratégique est-elle formalisée ?' },
                { questID: 45, question: 'La feuille de route stratégique est-elle suivie ?' },
                { questID: 46, question: 'La feuille de route stratégique est-elle revue régulièrement ?' }
              ] }
            ]
          },
          {
            titre: 'Partage avec les acteurs de l’entreprise',
            sousContextes: [
              { titre: 'Partage de la vision', questions: [
                { questID: 47, question: 'y a-t-il un partage interne restreint ?' },
                { questID: 48, question: 'y a-t-il un partage interne élargi ?' },
                { questID: 49, question: 'y a-t-il un partage externe avec des partenaires clefs ?' },
                { questID: 50, question: 'y a-t-il un partage avec l\'écosystème ?' }
              ] }
            ]
          }
        ];

        setContextesVision(contextes);
      } catch (error) {
        console.error('Erreur lors du chargement des contextes:', error);
      }
    };

    fetchContexteGeneraux();
  }, []);

  return <Questionnaire contextesData={contextesVision} collectionName="vision" />;
};

export default Vision;
